import "./ViewContainer.scss";
import { Carousel } from "@hallmark/web.core.surfaces.carousel";
import UnityView from "../UnityView/UnityView";
import MoreInfoView from "../MoreInfoView/MoreInfoView";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAnalyticsContext } from "../../context/analytics-context";
import { useAppContext } from "../../context/app-context/app-context";
import { usePixelDensity } from "../../hooks/usePixelDensity";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
const ViewContainer = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [inputData, setInputData] = useState({});
  const [navIndex, setNavIndex] = useState(0);
  const [token, setToken] = useState("");
  const query = useQuery();

  const {
    trackMoreButtonClick,
    trackCoverButtonClick,
    trackInsideButtonClick,
  } = useAnalyticsContext();

  const { showButtons, moveToSection, setMoveToSection } = useAppContext();

  const { devicePixelRatio } = usePixelDensity();

  useEffect(() => {
    setToken(query.get("token"));
  }, [query]);

  const onAfterSlide = (args) => {
    setSlideIndex(args);
  };

  const MoveToInside = () => {
    setNavIndex(2);
    setSlideIndex(0);
    const dataObject = { EventName: "moveToSection", EventValue: "inside" };
    setInputData(dataObject);
    trackInsideButtonClick();
  };

  const MoveToCover = () => {
    // console.log("SETTING NAV INDEX TO 1");
    setNavIndex(1);
    setSlideIndex(0);
    const dataObject = { EventName: "moveToSection", EventValue: "cover" };
    setInputData(dataObject);
    trackCoverButtonClick();
  };

  const MoveToCoverSilent = () => {
    // console.log("SETTING NAV INDEX TO 1");
    setNavIndex(1);
    setSlideIndex(0);
    const dataObject = {
      EventName: "moveToSectionSilent",
      EventValue: "cover",
    };
    setInputData(dataObject);
    trackCoverButtonClick();
  };

  const ReplayAnimation = () => {
    setNavIndex(0);
    setSlideIndex(0);
    const dataObject = { EventName: "replayAnimation", EventValue: "true" };
    setInputData(dataObject);
  };

  useEffect(() => {
    switch (moveToSection) {
      case "more":
        setSlideIndex(1);
        setNavIndex(3);
        break;
      case "coverSilent":
        console.log("MOVING TO COVER");
        MoveToCoverSilent();
        break;
      default:
        break;
    }
    setMoveToSection("");
  }, [moveToSection]);

  return (
    <div className="carousel-container">
      <div className="top-container">
        <a href="https://www.hallmark.com/">
          <img src="/static/img/logo-hallmark-crown.svg" alt="Hallmark" />
        </a>
      </div>
      <Carousel
        dragging={false}
        currentSlideIndex={slideIndex}
        onAfterSlide={onAfterSlide}
        slideClass="unityViewSlide"
      >
        <UnityView token={token} inputData={inputData} devicePixelRatio={devicePixelRatio} />
        <MoreInfoView />
      </Carousel>
      {/* The buttons should only appear when the animation ends, and the navigation options should move differently from the play button. */}
      {showButtons && navIndex !== 0 && (
        <div className="bottom-nav-container">
          <div className="bottom-nav-inner">
            <button
              onClick={() => {
                ReplayAnimation();
              }}
            >
              <div className="bottom-nav-icon">
                <div className="icon">
                  <img
                    src="/static/img/graphic-play.svg"
                    alt="Play Animation"
                  />
                </div>
                <div className="label">
                  {navIndex === 0 ? (
                    <img src="/static/img/graphic-dot.svg" alt="Cover" />
                  ) : (
                    "PLAY"
                  )}
                </div>
              </div>
            </button>
            <button
              onClick={() => {
                MoveToCover();
              }}
            >
              <div className="bottom-nav-icon">
                <div className="icon">
                  <img src="/static/img/graphic-card-cover-3.svg" alt="Cover" />
                </div>
                <div className="label">
                  {navIndex === 1 ? (
                    <img src="/static/img/graphic-dot.svg" alt="Cover" />
                  ) : (
                    "COVER"
                  )}
                </div>
              </div>
            </button>
            <button
              onClick={() => {
                MoveToInside();
              }}
            >
              <div className="bottom-nav-icon">
                <div className="icon">
                  <img src="/static/img/graphic-card-inside.svg" alt="Inside" />
                </div>
                <div className="label">
                  {navIndex === 2 ? (
                    <img src="/static/img/graphic-dot.svg" alt="Cover" />
                  ) : (
                    "INSIDE"
                  )}
                </div>
              </div>
            </button>
            <button
              onClick={() => {
                setNavIndex(3);
                setSlideIndex(1);
                trackMoreButtonClick();
              }}
            >
              <div className="bottom-nav-icon">
                <div className="icon">
                  <img
                    src="/static/img/graphic-more-menu.svg"
                    alt="What's Next?"
                  />
                </div>
                <div className="label">
                  {navIndex === 3 ? (
                    <img src="/static/img/graphic-dot.svg" alt="Cover" />
                  ) : (
                    "MORE"
                  )}
                </div>
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewContainer;
