import { useEffect, useState } from "react";

export const usePixelDensity = () => {
  const maxPixelDensity = 5;
  const [devicePixelRatio, setDevicePixelRatio] = useState(window.devicePixelRatio < maxPixelDensity ? window.devicePixelRatio : maxPixelDensity);

  useEffect(() => {
    const updateDevicePixelRatio = () => {
      setDevicePixelRatio(window.devicePixelRatio < maxPixelDensity ? window.devicePixelRatio : maxPixelDensity);
      /*
        All available information suggests this is the most efficient and 
        error-free way to handle sudden changes in pixel density.
      */
      window.location.reload();
    };
  
    const mediaMatcher = window.matchMedia(`screen and (resolution: ${devicePixelRatio}dppx)`);

    mediaMatcher.addEventListener("change", updateDevicePixelRatio);

    return () => {
      mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
    };
  },[devicePixelRatio]);

  return {devicePixelRatio};
};