import "./BirthdayWithCustomName.scss";
import { drawText } from 'canvas-txt';
import { Carousel } from "@hallmark/web.core.surfaces.carousel";
import StaticUnityViewWithCustomName from "../StaticUnityViewWithCustomName/StaticUnityViewWithCustomName";
import MoreInfoView from "../../MoreInfoView/MoreInfoView";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAnalyticsContext } from "../../../context/analytics-context";
import { useAppContext } from "../../../context/app-context/app-context";
import { usePixelDensity } from "../../../hooks/usePixelDensity";

const WebFont = require('webfontloader');

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
const BirthdayWithCustomName = () => {
  const baseUrl = window.location.origin;
  const [slideIndex, setSlideIndex] = useState(0);
  const [inputData, setInputData] = useState({});
  const [navIndex, setNavIndex] = useState(0);
  const [name, setName] = useState("");
  const [cardCoverData, setCardCoverData] = useState("");
  const [insideCardData, setInsideCardData] = useState("");
  const [hasFont, setHasFont] = useState(false);
  const query = useQuery();
  const baseCardImages = [
    baseUrl + "/static/img/DCALOYALTY_P1_Link.png",
    baseUrl + "/static/img/DCALOYALTY_P2-3_Background.png"
  ];

  const [cardImages, setCardImages] = useState([]);
  const cardFormat = "portrait";

  const {
    trackMoreButtonClick,
    trackCoverButtonClick,
    trackInsideButtonClick,
  } = useAnalyticsContext();

  const { showButtons, moveToSection, setMoveToSection } = useAppContext();

  const { devicePixelRatio } = usePixelDensity();

  const LoadFont = () => {
    WebFont.load({
      custom: {
        families: ['HeySunshine'],
      },
      active : () => {
        setHasFont(true);
      }
    })
  }
  useEffect(() => {
    LoadFont();
    setName(query.get("name"));
  }, []);

  useEffect(() => {
    if(name !== "" && hasFont){
      loadCoverPanel(baseCardImages[0]);
      addTextToImage(baseCardImages[1], name);
    }
  }, [name, hasFont]);

  const loadCoverPanel = (image) => {
    const canvas = document.createElement("canvas");
    canvas.width=512;
    canvas.height=733;
    const context = canvas.getContext("2d");
    var imageObj = new Image();
    imageObj.setAttribute('crossorigin','anonymous');
    imageObj.onload = function(){
      context.drawImage(imageObj, 0, 0, canvas.width, canvas.height);
      const finalImage = (canvas.toDataURL("image/jpeg")).replace('data:image/jpeg;base64,', '');
      setCardCoverData(finalImage);
    };
    const imageSrcFinal = image + "?cb=" + Date.now();
    imageObj.src = imageSrcFinal;
  };

  const addTextToImage = (image, msg) => {
    const imageContainer = document.getElementById("imageContainer");
    const canvas = document.createElement("canvas");
    canvas.width=1024;
    canvas.height=753;
    const context = canvas.getContext("2d");
    const viewer = document.getElementById("viewer");
    var imageObj = new Image();
    imageObj.setAttribute('crossorigin','anonymous');
    imageObj.onload = function(){
      context.drawImage(imageObj, 0, 0, canvas.width, canvas.height);
      context.fillStyle= "#5C468E";
      // context.clearRect(0, 0, canvas.width, canvas.height);
      const { height } = drawText(context, msg, {
        x: canvas.width/2,
        y: 0,
        width:canvas.width/2,
        height:270,
        fontSize:45,
        align: 'center',
        vAlign: 'bottom',
        font:'HeySunshine',
      });
      const finalImage = (canvas.toDataURL("image/jpeg")).replace('data:image/jpeg;base64,', '');
      setInsideCardData(finalImage);
    };
    const imageSrcFinal = image + "?cb=" + Date.now();
    imageObj.src = imageSrcFinal;
    // 
  }

  useEffect(() => {
    if(cardCoverData && cardCoverData !== '' && insideCardData && insideCardData !== ''){
      setCardImages([cardCoverData, insideCardData]);
    }
  }, [cardCoverData, insideCardData]);

  const onAfterSlide = (args) => {
    setSlideIndex(args);
  };

  const MoveToInside = () => {
    setNavIndex(2);
    setSlideIndex(0);
    const dataObject = { EventName: "moveToSection", EventValue: "inside" };
    setInputData(dataObject);
    trackInsideButtonClick();
  };

  const MoveToCover = () => {
    setNavIndex(1);
    setSlideIndex(0);
    const dataObject = { EventName: "moveToSection", EventValue: "cover" };
    setInputData(dataObject);
    trackCoverButtonClick();
  };

  const MoveToCoverSilent = () => {
    setNavIndex(1);
    setSlideIndex(0);
    const dataObject = {
      EventName: "moveToSectionSilent",
      EventValue: "cover",
    };
    setInputData(dataObject);
    trackCoverButtonClick();
  };

  const ReplayAnimation = () => {
    setNavIndex(0);
    setSlideIndex(0);
    const dataObject = { EventName: "replayAnimation", EventValue: "true" };
    setInputData(dataObject);
  };

  useEffect(() => {
    switch (moveToSection) {
      case "more":
        setSlideIndex(1);
        setNavIndex(3);
        break;
      case "coverSilent":
        MoveToCoverSilent();
        break;
      default:
        break;
    }
    setMoveToSection("");
  }, [moveToSection]);  

  return (
    <div className="carousel-container">
      <div className="top-container">
        <a href="https://www.hallmark.com/">
          <img src="/static/img/logo-hallmark-crown.svg" alt="Hallmark" />
        </a>
      </div>
      <Carousel
        dragging={false}
        currentSlideIndex={slideIndex}
        onAfterSlide={onAfterSlide}
        slideClass="unityViewSlide"
      >
        {
          cardImages && cardImages.length > 0 && (
            <StaticUnityViewWithCustomName recipientName={name} cardImages={cardImages} cardFormat={cardFormat} inputData={inputData} devicePixelRatio={devicePixelRatio} />
          )
        }
        <MoreInfoView />
      </Carousel>
      {/* The buttons should only appear when the animation ends, and the navigation options should move differently from the play button. */}
      {(showButtons && navIndex !== 0) && (
        <div className="bottom-nav-container">
          <div className="bottom-nav-inner">
            <button
              onClick={() => {
                ReplayAnimation();
              }}
            >
              <div className="bottom-nav-icon">
                <div className="icon">
                  <img
                    src="/static/img/graphic-play.svg"
                    alt="Play Animation"
                  />
                </div>
                <div className="label">
                  {navIndex === 0 ? (
                    <img src="/static/img/graphic-dot.svg" alt="Cover" />
                  ) : (
                    "PLAY"
                  )}
                </div>
              </div>
            </button>
            <button
              onClick={() => {
                MoveToCover();
              }}
            >
              <div className="bottom-nav-icon">
                <div className="icon">
                  <img src="/static/img/graphic-card-cover-3.svg" alt="Cover" />
                </div>
                <div className="label">
                  {navIndex === 1 ? (
                    <img src="/static/img/graphic-dot.svg" alt="Cover" />
                  ) : (
                    "COVER"
                  )}
                </div>
              </div>
            </button>
            <button
              onClick={() => {
                MoveToInside();
              }}
            >
              <div className="bottom-nav-icon">
                <div className="icon">
                  <img src="/static/img/graphic-card-inside.svg" alt="Inside" />
                </div>
                <div className="label">
                  {navIndex === 2 ? (
                    <img src="/static/img/graphic-dot.svg" alt="Cover" />
                  ) : (
                    "INSIDE"
                  )}
                </div>
              </div>
            </button>
            <button
              onClick={() => {
                setNavIndex(3);
                setSlideIndex(1);
                trackMoreButtonClick();
              }}
            >
              <div className="bottom-nav-icon">
                <div className="icon">
                  <img
                    src="/static/img/graphic-more-menu.svg"
                    alt="What's Next?"
                  />
                </div>
                <div className="label">
                  {navIndex === 3 ? (
                    <img src="/static/img/graphic-dot.svg" alt="Cover" />
                  ) : (
                    "MORE"
                  )}
                </div>
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BirthdayWithCustomName;
