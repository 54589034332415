import "./UnityView.scss";
import React, { useEffect, useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import axios from "axios";
import { LoadingIndicator } from "@hallmark/web.core.feedback.loading-indicator";
import { GetBaseUrl } from "../../utils/Environment";
import { useAnalyticsContext } from "../../context/analytics-context";
import { useAnalytics } from "../../context/analytics-context/analytics-context-hooks";
import { useAppContext } from "../../context/app-context/app-context";

const UnityView = (props) => {
  const { inputData, token, devicePixelRatio } = props;
  const { dataLayerAvailable } = useAnalytics();
  const { dataReceiverReady, setDataReceiverReady } = useState(false);

  const defaultViewStyle = {
    height: "60vh",
  };

  const fullViewStyle = {
    height: "75vh",
  };

  const [viewStyle, setViewStyle] = useState(defaultViewStyle);

  const {
    trackAbandon,
    trackAnimationPlayStart,
    trackAnimationPlayCompleted,
    trackPageLoaded,
    trackPinchGesture,
    trackDoubleTapGesture,
    trackError,
    setProductID,
    setProjectID,
    createStatusMessage,
    ACTIONS,
  } = useAnalyticsContext();

  const { showButtons, setShowButtons, setMoveToSection } = useAppContext();

  const { unityProvider, isLoaded, sendMessage, addEventListener, removeEventListener, loadingProgression, initialisationError } = useUnityContext({
    loaderUrl: "/static/unity/Build/bb0d9ecdb05db3e84da20bd14a4f84dc.loader.js",
    dataUrl: "/static/unity/Build/7860b77c55b516a2a9b89526c2ee7e1d.data",
    frameworkUrl: "/static/unity/Build/5b9e751a62c1d50f79ed9abd35d18a97.framework.js",
    codeUrl: "/static/unity/Build/5018905c1b6e5ef7e1070143bcc127c1.wasm",
    adjustCanvasToContainer: true,
  });

  useEffect(()=>{
    console.log("loadingProgression", loadingProgression);
  },[loadingProgression]);

  useEffect(()=>{
    if(initialisationError){
      console.log("initialisationError", initialisationError);
    }
  }, [initialisationError]);

  useEffect(()=>{
    if(isLoaded){
      console.log("Unity module is loaded");
    }
  }, [isLoaded]);

  const [unityIsLoaded, setUnityIsLoaded] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [assetData, setAssetData] = useState({});
  const [viewReported, setViewReported] = useState(false);
  const [viewed, setProjectViewed] = useState(false);
  const [animationState, setAnimationState] = useState(null);
  const animationTime = useRef(0.0);

  useEffect(() => {
    if (inputData !== undefined) {
      sendMessage(
        "DataReceiver",
        "LoadCustomEventData",
        JSON.stringify(inputData)
      );
    }
  }, [inputData, sendMessage]);

  const handleAnimationConfirmation = () => {
    setProjectViewed(true);
    trackAnimationPlayCompleted();
    setAnimationState(null);
  };

  const handleEventTrigger = (eventDataString) => {
    const eventData = JSON.parse(eventDataString);
    const { EventName, EventValue } = eventData;
    switch (EventName) {
      case "AnimationState":
        setAnimationState(EventValue);
        break;

      case "AnimationTime":
        const eventFloatVal = parseFloat(EventValue);
        animationTime.current = eventFloatVal;
        break;

      case "ActionDoubleTap":
        const zoomDirection = (EventValue) => {
          if (EventValue === "DirectionIn") {
            return "zoom in";
          } else if (EventValue === "DirectionOut") {
            return "zoom out";
          }
        };
        trackDoubleTapGesture(zoomDirection);
        break;

      case "ActionPinch":
        const pinchDirection = (EventValue) => {
          if (EventValue === "DirectionIn") {
            return "zoom in";
          } else if (EventValue === "DirectionOut") {
            return "zoom out";
          }
        };
        trackPinchGesture(pinchDirection);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if(dataReceiverReady && projectData !== "{}")
    {
      setUnityIsLoaded(true);
      sendUnityContent();
    }
  }, [projectData, dataReceiverReady])

  const handleDataReceiverReady = () => {
    setDataReceiverReady(true);
  }

  const handleMoveToMoreSection = () => {
    setMoveToSection("more");
  };

  const handleMoveToCoverSection = () => {
    setMoveToSection("coverSilent");
  }

  const handleEnableButtons = () => {
    setShowButtons(true);
  };

  const handleDisableButtons = () => {
    setShowButtons(false);
  };

  useEffect(() => {
    if(isLoaded){
      addEventListener("debug", console.log);
      addEventListener("AnimationConfirmation", handleAnimationConfirmation);
      addEventListener("DisableButtons", handleDisableButtons);
      addEventListener("EnableButtons", handleEnableButtons);
      addEventListener("MoveToMoreSection", handleMoveToMoreSection);
      addEventListener("MoveToCoverSection", handleMoveToCoverSection);
      addEventListener("EventTrigger", handleEventTrigger);
      addEventListener("DataReceiverReady", handleDataReceiverReady);  
    }

    return () => {
      removeEventListener("debug", console.log);
      removeEventListener("AnimationConfirmation", handleAnimationConfirmation);
      removeEventListener("DisableButtons", handleDisableButtons);
      removeEventListener("EnableButtons", handleEnableButtons);
      removeEventListener("MoveToMoreSection", handleMoveToMoreSection);
      removeEventListener("MoveToCoverSection", handleMoveToCoverSection);
      removeEventListener("EventTrigger", handleEventTrigger);
      removeEventListener("DataReceiverReady", handleDataReceiverReady);
    }
  }, [isLoaded]);

  useEffect(() => {
    if(token && isLoaded){
      FetchProjectData();
    }
  }, [token, isLoaded]);

  const sendUnityContent = () => {
    sendMessage(
      "DataReceiver",
      "LoadCustomizedDataFromURL",
      JSON.stringify(projectData)
    );

    trackPageLoaded();
  };

  window.sendUnityContent = sendUnityContent;

  const projectDataModel = {
    projectId: "123456",
    templateType: "portrait",
    previewMode: false,
    showCoverPreview: false,
    qualityLevel: 1,
    //2 - 0.5
    //4 - 0.65
    //5 - 0.75
    //6 - 0.85
    cardPanelData: [
      {
        index: 0,
        panelUrl:
          "https://content.hallmark.com/webassets/PGM1251/PGM1251_P1_Background.png",
      },
      {
        index: 1,
        panelUrl:
          "https://content.hallmark.com/webassets/PGM1251/PGM1251_P2-3_Background.png",
      },
    ],
  };

  function FetchProjectData() {
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const endPoint = GetBaseUrl() + "/customization/recipient-assets";
    const patchEndpoint =
      GetBaseUrl() + "/customization/recipient-assets/view-date";
    axios
      .get(endPoint, config)
      .then((res) => {
        const resData = res.data.data;
        setViewReported(resData.viewed);
        setAssetData(res.data);

        projectDataModel.templateType = resData.card_format;
        projectDataModel.cardPanelData[0].panelUrl = resData.assets[0];
        projectDataModel.cardPanelData[1].panelUrl = resData.assets[1];

        setProjectData(projectDataModel);

        if (resData.project_id !== undefined && resData.project_id !== "") {
          setProjectID(resData.project_id);
        }

        if (resData.product_id !== undefined && resData.product_id !== "") {
          setProductID(resData.product_id);
        }

        setUnityIsLoaded(true);
      })
      .catch((err) => {
        const actionName = ACTIONS.LOAD_ASSETS;
        const messages = createStatusMessage(err);
        trackError(messages, actionName);
      });
  }

  function updateProjectView() {
    const patchEndpoint =
      GetBaseUrl() + "/customization/recipient-assets/view-date";

    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios
      .patch(patchEndpoint, null, config)
      .then((res) => {
        setViewReported(true);
      })
      .catch((err) => console.log(err));
  }

  const GetAnimationTime = () => {
    return animationTime.current;
  };

  useEffect(() => {
    if (JSON.stringify(projectData) !== "{}") {
      sendUnityContent();
    }
  }, [projectData]);

  useEffect(() => {
    if (viewed && !viewReported) {
      updateProjectView();
    }
  }, [viewed]);

  useEffect(() => {
    if (token !== "" && dataLayerAvailable === true) {
      
      window.addEventListener("beforeunload", (event) => {
        trackAbandon(GetAnimationTime());
      });
    }
  }, [token, dataLayerAvailable]);

  useEffect(() => {
    switch (animationState) {
      case "Start":
        trackAnimationPlayStart();
        break;
    }
  }, [animationState]);

  useEffect(() => {
    if (showButtons) {
      setViewStyle(defaultViewStyle);
    } else {
      setViewStyle(fullViewStyle);
    }
  }, [showButtons]);

  return (
    <div className="contentContainer">
      {!unityIsLoaded && (
        <>
          <LoadingIndicator
            title="Loading Digital Greeting"
            description="Your Digital Greeting is Loading"
            role="img"
            addClass=""
          />
        </>
      )}
      <div className="animationContent" style={viewStyle}>
        <Unity
          unityProvider={unityProvider}
          className={"animationContainer " + (unityIsLoaded ? "show" : "hidden")}
          devicePixelRatio={devicePixelRatio}
        />
      </div>
    </div>
  );
};

export default UnityView;
